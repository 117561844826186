
$baseColor: #000;

$textColor: #666;
$fontSize: 14px;
$lineHeight: 2.4;

$colorGold: #B59658;
$colorLightGold: #c5ad7c;

/* font-family */
$fontBase: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", 'Open Sans', YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
$fontMincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
$fontJa: 'Noto Sans Japanese', serif;
$fontEn: 'Cormorant Garamond', serif;
