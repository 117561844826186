@charset "utf-8";

@import "variables";


#main-view-wrap{
	position: relative;
}
#main-view{
	width: 100%;
	height: 100vh;
	.in{
		overflow: hidden;
		width: 100%;
		height: 100%;
	}
	span{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		background-position: center 60%;
		background-size: cover;
		transition: transform 12s linear,
					opacity 1s linear;
	}
	span.show{
		opacity: 1;
	}
	span:nth-child(odd) {
		transform: scale(1.1);
	}
	span:nth-child(odd).show{
		transform: scale(1);
	}
	span:nth-child(even) {
		transform: scale(1);
	}
	span:nth-child(even).show{
		transform: scale(1.1);
	}
}
#main-view:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.2);
}
#main-caption{
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	width: 100%;
	height: auto;
	transform: translate(-50%, -50%);
	small{
		display: block;
		color: #fff;
		font-size: 12px;
		opacity: .6;
		margin: 5px 0 0;
		letter-spacing: .2em;
	}
}
#logo-slides{
	line-height: 0;
	padding: 0 5%;
	img{
		width: 80%;
		max-width: 300px;
		margin: auto;
		opacity: .6;
	}
}

.sec.sec01{
	// padding-bottom: 0;
	.sec-head{
		h2{
			img{
				width: 80%;
				max-width: 500px;
			}
		}
	}
}
#nav-store{
	ul{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		li{
			display: flex;
			align-items: stretch;
			width: 33.33%;
			padding: 5px;
			.li-in{
				cursor: pointer;
				position: relative;
				display: block;
				width: 100%;
				height: 0;
				padding-top: 75%;
				// background-color: #1E1E1E;
				background-color: rgba(0,0,0,.1);
				transition: background-color .6s;

				> div{
					span{
						text-align: center;
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						height: auto;
						line-height: 2;
						transform: translate(-50%, -50%);
						small{
							display: none;
							color: $colorGold;
							font-family: $fontEn;
							font-weight: 400;
						}
						strong{
							display: block;
							// color: #fff;
							color: #000;
							font-size: 14px;
							font-family: $fontMincho;
							transition: color .6s;
						}
					}
				}
				> div:before,
				> div:after{
					content: '';
					display: block;
					position: absolute;
					bottom: 10px;
					width: 30px;
					height: 30px;
					border-bottom: 1px solid rgba(0,0,0,.2);
				}
				> div:before{
					left: 10px;
					border-left: 1px solid rgba(0,0,0,.2);
				}
				> div:after{
					right: 10px;
					border-right: 1px solid rgba(0,0,0,.2);
				}
				
			}
			.li-in:before,
			.li-in:after{
				content: '';
				display: block;
				position: absolute;
				top: 10px;
				width: 30px;
				height: 30px;
				border-top: 1px solid rgba(0,0,0,.2);
			}
			.li-in:before{
				left: 10px;
				border-left: 1px solid rgba(0,0,0,.2);
			}
			.li-in:after{
				right: 10px;
				border-right: 1px solid rgba(0,0,0,.2);
			}
			.li-in:hover{
				background-color: rgba(0,0,0,1);
				> div{
					span{
						strong{
							color: #fff;
						}
					}
				}
			}
		}
	}
}

.sec.sec02{
	position: relative;
	// margin-top: -120px;
	// padding-top: 160px;
	background-image: url(../img/top/sec02/bg.jpg);
	background-position: left center;
	background-size: cover;
	h2{
		color: #fff;
		small{
			color: rgba(255,255,255,.6);
		}
	}
}
/*
.sec.sec02:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(../img/top/sec02/bg.jpg) no-repeat center;
	background-size: cover;
	// opacity: .2;
}
*/
.sec.sec02:after{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(to top right, rgba(0,0,0,.9) 50%, rgba(0,0,0,.4) 100%);
	// background: linear-gradient(60deg, rgba(255,255,255,1) 60%, rgba(0,0,0,0) 80%);
}
#post-news{
	article{
		.art-in{
			overflow: hidden;
			position: relative;
			top: 0;
			width: 100%;
			margin: 25px 0 0;
			.art-head{
				float: left;
				width: 35%;
				span{
					line-height: 0;
					overflow: hidden;
					display: block;
					position: relative;
					width: 100%;
					height: 0;
					padding-top: 75%;
					background-color: rgba(0,0,0,.2);
					background-image: url(https://skyclub.co.jp/wp-content/themes/skyclub/assets/img/common/ajax-loader.gif);
					background-repeat: no-repeat;
					background-position: 50% 50%;
					background-size: 24px auto;
					img{
						position: absolute;
						top: 50%;
						left: 50%;
						width: 100%;
						height: auto;
						transform: translate(-50%, -50%);
						transition: transform .3s;
					}
				}
			}
			.art-body{
				margin: 0 0 0 35%;
				padding: 0 0 0 20px;
				h3{
					color: #fff;
				}
				.art-meta{
					text-align: right;
					font-size: 13px;
					margin: 15px 0 0;
					padding: 5px 0 0;
					border-top: 1px solid rgba(255,255,255,.2);
					color: rgba(255,255,255,.6);
					span{
						display: inline-block;
						margin: 0 5px;
						i{
							margin-right: 2.5px;
						}
					}
				}
			}
		}
		.art-in:hover{
			.art-head{
				span{
					img{
						transform: translate(-50%, -50%) scale(1.1);
					}
				}
			}
		}
	}
}

.sec.sec03{
	position: relative;
	// background-color: #eee;
	// background: linear-gradient(to right, #f0f0f0 30%, #fcfcfc 90%);
}
ul#post-event{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	li{
		display: flex;
		align-items: stretch;
		width: 50%;
		// margin: 0 0 20px;
		padding: 10px;
		.li-in{
			position: relative;
			top: 0;
			background-color: #fff;
			box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),
						0px 2px 2px 0px rgba(0,0,0,0.14),
						0px 1px 5px 0px rgba(0,0,0,0.12);
			transition: top .65s cubic-bezier(0.19, 1, 0.22, 1),
						box-shadow .65s cubic-bezier(0.19, 1, 0.22, 1);
			.li-head{
				span{
					display: block;
					line-height: 0;
					background-image: url(https://skyclub.co.jp/wp-content/themes/skyclub/assets/img/common/ajax-loader.gif);
					background-repeat: no-repeat;
					background-position: 50% 50%;
					background-size: 24px auto;
				}
			}
			.li-body{
				padding: 20px 25px 25px;
				p{
					overflow: hidden;
					max-height: 7em;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					opacity: .9;
					font-size: 13px;
				}
			}
		}
		.li-in:hover{
			z-index: 1;
			top: -10px;
			box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),
						0px 8px 10px 1px rgba(0,0,0,0.14),
						0px 3px 14px 2px rgba(0,0,0,0.12);
		}
	}
}

.sec.sec04{

}
#post-media{
	li{
		.li-in{
			position: relative;
			top: 0;
			width: 100%;
			margin: 25px 0 0;
			.li-head{
				float: left;
				width: 35%;
				span{
					display: block;
					line-height: 0;
				}
			}
			.li-body{
				margin: 0 0 0 35%;
				padding: 0 0 0 20px;
				h3{
					color: #000;
				}
				.li-date{
					text-align: right;
					font-size: 13px;
					margin: 15px 0 0;
					padding: 5px 0 0;
					border-top: 1px solid rgba(0,0,0,.2);
					color: #666;
					i{
						margin-right: 2.5px;
					}
				}
			}
		}
	}
}

#store-wrap{
	overflow-y: scroll;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 8;
	width: 100%;
	height: 100%;
	background-color: #fff;
	transform: translateX(100%);
	transition: transform .65s cubic-bezier(0.19, 1, 0.22, 1);
	.p-store{
		display: none;
		.p-store-head{
			position: relative;
			text-align: center;
			padding: 50px 5%;
			background-color: $colorLightGold;
			h4{
				color: #fff;
				small{
					display: block;
					color: #eee;
					font-family: $fontEn;
					font-weight: 400;
				}
			}
			.sns{

				display: none !important;

				color: #fff;
				margin-top: 20px;
				ul{
					li{
						a{
							img{
								width: 30px;
								height: 30px;
							}
						}
					}
				}
			}
		}
		.p-store-body{
			padding: 25px 5% 50px;
			line-height: 2;
			ul{
				li{
					padding: 30px 0;
					border-top: 1px dotted rgba(0,0,0,.2);
					.li-in{
						overflow: hidden;
						position: relative;
						// box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
						.li-img{
							float: left;
							width: 40%;
							span{
								overflow: hidden;
								position: relative;
								display: block;
								width: 100%;
								height: 0;
								padding-top: 75%;
								background-image: url(https://skyclub.co.jp/wp-content/themes/skyclub/assets/img/common/ajax-loader.gif);
								background-repeat: no-repeat;
								background-position: 50% 50%;
								background-size: 24px auto;
								img{
									position: absolute;
									top: 50%;
									left: 50%;
									width: auto;
									height: 100%;
									transform: translate(-50%, -50%);
									transition: transform .3s;
								}
							}
						}
						.li-dtl{
							margin: 0 0 0 40%;
							padding: 0 0 0 8%;
							h5{
								line-height: 1.6;
								margin: 0 0 5px;
							}
						}
					}
					.li-in:hover{
						.li-img{
							span{
								img{
									transform: translate(-50%, -50%) scale(1.1);
								}
							}
						}
					}
				}
				li:first-of-type{
					border: 0;
				}
			}
		}
	}
}
#store-wrap.on{
	transform: translateX(0);
}
#store-overlay{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 7;
	width: 100%;
	height: 100%;
	display: none;
	background-color: rgba(0,0,0,0.5);
}



@media screen and (min-width: 600px) {
	.sec.sec01{

	}
	#nav-store{
		ul{
			li{
				.li-in{
					> div{
						span{
							small{
								display: block;
							}
							strong{
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}

	.sec.sec02{
		// padding-top: 180px;
	}
	#post-news{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px -15px;
		article{
			display: flex;
			align-items: stretch;
			width: 50%;
			padding: 15px;
			.art-in{
				margin: 0;
				.art-head{
					float: none;
					width: 100%;
				}
				.art-body{
					margin: 0;
					padding: 15px 10px 0;
				}
			}
		}
	}

	.sec.sec03{

	}
	ul#post-event{
		li{
			width: 33.333333%;
		}
	}

	.sec.sec04{

	}
	#post-media{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		li{
			display: flex;
			align-items: stretch;
			width: 50%;
			padding: 15px;
			.li-in{
				margin: 0;
				.li-head{
					float: none;
					width: 100%;
				}
				.li-body{
					margin: 0;
					padding: 15px 10px 0;
				}
			}
		}
	}

}


@media screen and (min-width: 800px) {
	#main-view{
		width: 65%;
		margin-left: 35%;
		// padding: 10px;
	}
	#main-view:before{
		display: none;
	}
	#main-caption{
		left: 0;
		width: 35%;
		transform: translate(0, -50%);
		small{
			color: $textColor;
			opacity: 1;
		}
	}
	#logo-slides{
		img{
			opacity: 1;
		}
	}

	.sec.sec01{
		
	}
	#nav-store{
		ul{
			li{
				width: 25%;
			}
		}
	}

	.sec.sec02{
		// padding-top: 200px;
	/*
		.sec-head,
		.sec-body{
			display: inline-block;
			vertical-align: top;
		}
		.sec-head{
			position: relative;
			width: 25%;
			height: 100%;
			margin: 0 0 0 -20px;
			h2{
				padding: 20% 0;
				small{
					margin-top: 25px;
				}
			}
		}
		.sec-body{
			width: 75%;
		}
	*/
	}
	.sec.sec02:before{
		// opacity: 1;
	}
	#post-news{
		article{
			width: 33.33%;
		}
	}

	.sec.sec03{

	}
	ul#post-event{
		li{
			width: 25%;
		}
	}

	.sec.sec04{

	}
	#post-media{
		li{
			width: 33.33%;
		}
	}

	#store-wrap{
		width: 50%;
		.p-store{
			.p-store-body{
				padding: 25px 10% 50px;
			}
		}
	}


}


@media screen and (min-width: 1000px) {
	#main-view{
		// padding: 15px;
	}

	.sec.sec01{
		// padding-bottom: 0;
	}
	#nav-store{
		ul{
			// margin: 0 -10px;
			li{
				width: 20%;
			}
		}
	}

	.sec.sec02{
		// margin-top: -120px;
		// padding-top: 220px;
	}
	#post-news{
		// margin: 0 -20px;
		article{
			// padding: 0 20px;
		}
	}

	.sec.sec04{

	}
	#post-media{
		margin: 0 -20px;
		li{
			padding: 0 20px;
		}
	}
}


@media screen and (min-width: 1200px) {
	#main-view{
		// padding: 20px;
	}
	#nav-store{
		ul{
			// margin: 0 -5px;
			li{
				.li-in{
					> div{
						span{
							strong{
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}

	.sec.sec01{
		
	}
	#nav-store{
		ul{
			margin: 0 -5px;
		}
	}

	.sec.sec02{
		// padding-top: 240px;
		.sec-head,
		.sec-body{
			// vertical-align: middle;
		}
	/*
		.sec-head{
			width: 20%;
			margin: 0;
			h2{
				text-align: left;
			}
		}
		.sec-body{
			width: 80%;
		}
	*/
	}
	#post-news{
		// margin: 0 -25px;
		article{
			width: 25%;
			// padding: 0 25px;
		}
	}

	.sec.sec03{

	}
	ul#post-event{
		li{
			width: 20%;
			// padding: 0 15px;
		}
	}

	.sec.sec04{

	}
	#post-media{
		margin: 0 -25px;
		li{
			padding: 0 25px;
		}
	}

}
