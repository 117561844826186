@charset "UTF-8";
/* font-family */
#main-view-wrap {
  position: relative;
}

#main-view {
  width: 100%;
  height: 100vh;
}

#main-view .in {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#main-view span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-position: center 60%;
  background-size: cover;
  transition: transform 12s linear, opacity 1s linear;
}

#main-view span.show {
  opacity: 1;
}

#main-view span:nth-child(odd) {
  transform: scale(1.1);
}

#main-view span:nth-child(odd).show {
  transform: scale(1);
}

#main-view span:nth-child(even) {
  transform: scale(1);
}

#main-view span:nth-child(even).show {
  transform: scale(1.1);
}

#main-view:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

#main-caption {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

#main-caption small {
  display: block;
  color: #fff;
  font-size: 12px;
  opacity: .6;
  margin: 5px 0 0;
  letter-spacing: .2em;
}

#logo-slides {
  line-height: 0;
  padding: 0 5%;
}

#logo-slides img {
  width: 80%;
  max-width: 300px;
  margin: auto;
  opacity: .6;
}

.sec.sec01 .sec-head h2 img {
  width: 80%;
  max-width: 500px;
}

#nav-store ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

#nav-store ul li {
  display: flex;
  align-items: stretch;
  width: 33.33%;
  padding: 5px;
}

#nav-store ul li .li-in {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: 75%;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color .6s;
}

#nav-store ul li .li-in > div span {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  line-height: 2;
  transform: translate(-50%, -50%);
}

#nav-store ul li .li-in > div span small {
  display: none;
  color: #B59658;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
}

#nav-store ul li .li-in > div span strong {
  display: block;
  color: #000;
  font-size: 14px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  transition: color .6s;
}

#nav-store ul li .li-in > div:before,
#nav-store ul li .li-in > div:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 10px;
  width: 30px;
  height: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

#nav-store ul li .li-in > div:before {
  left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

#nav-store ul li .li-in > div:after {
  right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

#nav-store ul li .li-in:before,
#nav-store ul li .li-in:after {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  width: 30px;
  height: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

#nav-store ul li .li-in:before {
  left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

#nav-store ul li .li-in:after {
  right: 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

#nav-store ul li .li-in:hover {
  background-color: black;
}

#nav-store ul li .li-in:hover > div span strong {
  color: #fff;
}

.sec.sec02 {
  position: relative;
  background-image: url(../img/top/sec02/bg.jpg);
  background-position: left center;
  background-size: cover;
}

.sec.sec02 h2 {
  color: #fff;
}

.sec.sec02 h2 small {
  color: rgba(255, 255, 255, 0.6);
}

/*
.sec.sec02:before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(../img/top/sec02/bg.jpg) no-repeat center;
	background-size: cover;
	// opacity: .2;
}
*/
.sec.sec02:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0.4) 100%);
}

#post-news article .art-in {
  overflow: hidden;
  position: relative;
  top: 0;
  width: 100%;
  margin: 25px 0 0;
}

#post-news article .art-in .art-head {
  float: left;
  width: 35%;
}

#post-news article .art-in .art-head span {
  line-height: 0;
  overflow: hidden;
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: url(https://skyclub.co.jp/wp-content/themes/skyclub/assets/img/common/ajax-loader.gif);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 24px auto;
}

#post-news article .art-in .art-head span img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  transition: transform .3s;
}

#post-news article .art-in .art-body {
  margin: 0 0 0 35%;
  padding: 0 0 0 20px;
}

#post-news article .art-in .art-body h3 {
  color: #fff;
}

#post-news article .art-in .art-body .art-meta {
  text-align: right;
  font-size: 13px;
  margin: 15px 0 0;
  padding: 5px 0 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.6);
}

#post-news article .art-in .art-body .art-meta span {
  display: inline-block;
  margin: 0 5px;
}

#post-news article .art-in .art-body .art-meta span i {
  margin-right: 2.5px;
}

#post-news article .art-in:hover .art-head span img {
  transform: translate(-50%, -50%) scale(1.1);
}

.sec.sec03 {
  position: relative;
}

ul#post-event {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

ul#post-event li {
  display: flex;
  align-items: stretch;
  width: 50%;
  padding: 10px;
}

ul#post-event li .li-in {
  position: relative;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: top 0.65s cubic-bezier(0.19, 1, 0.22, 1), box-shadow 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

ul#post-event li .li-in .li-head span {
  display: block;
  line-height: 0;
  background-image: url(https://skyclub.co.jp/wp-content/themes/skyclub/assets/img/common/ajax-loader.gif);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 24px auto;
}

ul#post-event li .li-in .li-body {
  padding: 20px 25px 25px;
}

ul#post-event li .li-in .li-body p {
  overflow: hidden;
  max-height: 7em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  opacity: .9;
  font-size: 13px;
}

ul#post-event li .li-in:hover {
  z-index: 1;
  top: -10px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

#post-media li .li-in {
  position: relative;
  top: 0;
  width: 100%;
  margin: 25px 0 0;
}

#post-media li .li-in .li-head {
  float: left;
  width: 35%;
}

#post-media li .li-in .li-head span {
  display: block;
  line-height: 0;
}

#post-media li .li-in .li-body {
  margin: 0 0 0 35%;
  padding: 0 0 0 20px;
}

#post-media li .li-in .li-body h3 {
  color: #000;
}

#post-media li .li-in .li-body .li-date {
  text-align: right;
  font-size: 13px;
  margin: 15px 0 0;
  padding: 5px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  color: #666;
}

#post-media li .li-in .li-body .li-date i {
  margin-right: 2.5px;
}

#store-wrap {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

#store-wrap .p-store {
  display: none;
}

#store-wrap .p-store .p-store-head {
  position: relative;
  text-align: center;
  padding: 50px 5%;
  background-color: #c5ad7c;
}

#store-wrap .p-store .p-store-head h4 {
  color: #fff;
}

#store-wrap .p-store .p-store-head h4 small {
  display: block;
  color: #eee;
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
}

#store-wrap .p-store .p-store-head .sns {
  display: none !important;
  color: #fff;
  margin-top: 20px;
}

#store-wrap .p-store .p-store-head .sns ul li a img {
  width: 30px;
  height: 30px;
}

#store-wrap .p-store .p-store-body {
  padding: 25px 5% 50px;
  line-height: 2;
}

#store-wrap .p-store .p-store-body ul li {
  padding: 30px 0;
  border-top: 1px dotted rgba(0, 0, 0, 0.2);
}

#store-wrap .p-store .p-store-body ul li .li-in {
  overflow: hidden;
  position: relative;
}

#store-wrap .p-store .p-store-body ul li .li-in .li-img {
  float: left;
  width: 40%;
}

#store-wrap .p-store .p-store-body ul li .li-in .li-img span {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: 75%;
  background-image: url(https://skyclub.co.jp/wp-content/themes/skyclub/assets/img/common/ajax-loader.gif);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 24px auto;
}

#store-wrap .p-store .p-store-body ul li .li-in .li-img span img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
  transition: transform .3s;
}

#store-wrap .p-store .p-store-body ul li .li-in .li-dtl {
  margin: 0 0 0 40%;
  padding: 0 0 0 8%;
}

#store-wrap .p-store .p-store-body ul li .li-in .li-dtl h5 {
  line-height: 1.6;
  margin: 0 0 5px;
}

#store-wrap .p-store .p-store-body ul li .li-in:hover .li-img span img {
  transform: translate(-50%, -50%) scale(1.1);
}

#store-wrap .p-store .p-store-body ul li:first-of-type {
  border: 0;
}

#store-wrap.on {
  transform: translateX(0);
}

#store-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 600px) {
  #nav-store ul li .li-in > div span small {
    display: block;
  }
  #nav-store ul li .li-in > div span strong {
    font-size: 18px;
  }
  #post-news {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -15px;
  }
  #post-news article {
    display: flex;
    align-items: stretch;
    width: 50%;
    padding: 15px;
  }
  #post-news article .art-in {
    margin: 0;
  }
  #post-news article .art-in .art-head {
    float: none;
    width: 100%;
  }
  #post-news article .art-in .art-body {
    margin: 0;
    padding: 15px 10px 0;
  }
  ul#post-event li {
    width: 33.333333%;
  }
  #post-media {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  #post-media li {
    display: flex;
    align-items: stretch;
    width: 50%;
    padding: 15px;
  }
  #post-media li .li-in {
    margin: 0;
  }
  #post-media li .li-in .li-head {
    float: none;
    width: 100%;
  }
  #post-media li .li-in .li-body {
    margin: 0;
    padding: 15px 10px 0;
  }
}

@media screen and (min-width: 800px) {
  #main-view {
    width: 65%;
    margin-left: 35%;
  }
  #main-view:before {
    display: none;
  }
  #main-caption {
    left: 0;
    width: 35%;
    transform: translate(0, -50%);
  }
  #main-caption small {
    color: #666;
    opacity: 1;
  }
  #logo-slides img {
    opacity: 1;
  }
  #nav-store ul li {
    width: 25%;
  }
  .sec.sec02 {
    /*
		.sec-head,
		.sec-body{
			display: inline-block;
			vertical-align: top;
		}
		.sec-head{
			position: relative;
			width: 25%;
			height: 100%;
			margin: 0 0 0 -20px;
			h2{
				padding: 20% 0;
				small{
					margin-top: 25px;
				}
			}
		}
		.sec-body{
			width: 75%;
		}
	*/
  }
  #post-news article {
    width: 33.33%;
  }
  ul#post-event li {
    width: 25%;
  }
  #post-media li {
    width: 33.33%;
  }
  #store-wrap {
    width: 50%;
  }
  #store-wrap .p-store .p-store-body {
    padding: 25px 10% 50px;
  }
}

@media screen and (min-width: 1000px) {
  #nav-store ul li {
    width: 20%;
  }
  #post-media {
    margin: 0 -20px;
  }
  #post-media li {
    padding: 0 20px;
  }
}

@media screen and (min-width: 1200px) {
  #nav-store ul li .li-in > div span strong {
    font-size: 20px;
  }
  #nav-store ul {
    margin: 0 -5px;
  }
  .sec.sec02 {
    /*
		.sec-head{
			width: 20%;
			margin: 0;
			h2{
				text-align: left;
			}
		}
		.sec-body{
			width: 80%;
		}
	*/
  }
  #post-news article {
    width: 25%;
  }
  ul#post-event li {
    width: 20%;
  }
  #post-media {
    margin: 0 -25px;
  }
  #post-media li {
    padding: 0 25px;
  }
}
